"use client"
import { useTheme, Palette } from "@mui/material/styles"
import isEqual from "lodash-es/isEqual"
import isUndefined from "lodash-es/isUndefined"
import map from "lodash-es/map"
import React, { FC, Fragment, ReactNode } from "react"

import { Card } from "../card"
import { Icon, IconName } from "../icon"
import { Link, LinkProps } from "../link"

import {
  ListProps,
  ListItemProps,
  ListDefaultItemProps,
  ListCardItemProps,
} from "./List.model"

import styles from "./List.module.scss"

import { create } from "@/helpers/bem"
import { ThemeOptionsGradiant } from "@/utils/mui"

const bem = create(styles, "List")

export const List: FC<ListProps> = ({
  type,
  variant = "inherit",
  className,
  options = {},
  items,
}) => {
  const theme = useTheme()
  const mode = theme.palette.mode
  const modifier = {
    [mode]: true,
    [`${type}`]: !!type,
  }
  const iconColor = (theme.palette as any)?.[variant as keyof Palette]?.main
  const renderTitle = (title: string, item?: ListDefaultItemProps) => (
    <span
      className={bem("item__title", {
        "is-clickable": !!item?.onClick,
        ...modifier,
      })}
    >
      {title}
    </span>
  )
  const renderLink = (props: LinkProps, children: ReactNode) => (
    <Link
      className={bem("item__link", undefined, props?.className)}
      variant={variant}
      {...props}
    >
      {children}
    </Link>
  )
  const renderIcon = (name?: IconName) => {
    if (!name) return null
    return (
      <div className={bem("item__icon__wrapper")}>
        <Icon color={iconColor} name={name} {...options?.icon} />
      </div>
    )
  }
  const renderCardItem = (item: ListCardItemProps) => {
    const icon = item?.icon
    return (
      <Card
        className={bem("item__card")}
        contentClassName={bem("item__card__content")}
        {...item}
      >
        {icon && (
          <div
            className={bem("item__icon", undefined, item?.iconProps?.className)}
            style={{
              background:
                theme.gradiant?.[
                  item?.variant ?? (variant as keyof ThemeOptionsGradiant)
                ],
            }}
          >
            <Icon
              {...item?.iconProps}
              name={icon}
              color={
                !isEqual(mode, "dark")
                  ? theme.palette.inherit.main
                  : theme.palette.inherit.contrastText
              }
            />
          </div>
        )}
        <div className={bem("item__content")}>
          {renderTitle(item.title)}
          {item?.desc && <p className={bem("item__desc")}>{item.desc}</p>}
        </div>
      </Card>
    )
  }

  const renderItem = (item: ListItemProps) => {
    switch (type) {
      case "card":
        return renderCardItem(item as ListCardItemProps)
      default:
        if (!isUndefined(item?.redirect)) {
          return renderLink(
            item.redirect,
            <Fragment>
              {renderIcon(item?.icon)}
              {renderTitle(item.title, item)}
            </Fragment>,
          )
        }
        return (
          <Fragment>
            {renderIcon(item?.icon)}
            {renderTitle(item.title, item)}
          </Fragment>
        )
    }
  }

  return (
    <ul className={bem(undefined, undefined, className)}>
      {map(items, (item, index) => {
        const isClickable = !!item?.onClick || !!item?.redirect
        return (
          <li
            key={`list-item-${index}`}
            className={bem("item", {
              "is-clickable": isClickable,
              [`${theme.palette.mode}--is-clickable--${item?.variant ?? variant}`]:
                isClickable,
              ...modifier,
            })}
            onClick={
              isEqual(type, "default") && item?.onClick?.()
                ? () => item?.onClick?.(item)
                : undefined
            }
          >
            {renderItem(item as ListItemProps)}
          </li>
        )
      })}
    </ul>
  )
}
