"use client"
import { usePathname } from "next/navigation"
import { FC } from "react"

import type {
  PromoBannerLocale,
  PromoBannerVariant,
} from "@/components/common/pomo-banner"
import { PromoBanner } from "@/components/common/pomo-banner"

import { resolveMediaUrl } from "@/helpers/api-helpers"
import { trackCTAAppDownload } from "@/helpers/tracking"
import type { ComponentLayoutFooterBanner } from "@/types/generated/strapi/graphql"
import type { Locale } from "@/utils/translations"

export type FooterPromoBannerProps = {
  locale: Locale
  data?: ComponentLayoutFooterBanner
}

export const FooterPromoBanner: FC<FooterPromoBannerProps> = ({
  locale,
  data,
}) => {
  const path = usePathname()
  const bannerImage = data?.imageSrc?.data?.attributes
  const bannerImageDark = data?.imageSrcDark?.data?.attributes
  return (
    <PromoBanner
      appleButtonUrl={data?.appStoreUrl}
      background={1}
      content={data?.description}
      id="footer-download"
      locale={locale as PromoBannerLocale}
      playButtonUrl={data?.playStoreUrl}
      title={data?.headline ?? ""}
      variant={data?.variant as PromoBannerVariant}
      image={{
        src: resolveMediaUrl(bannerImage?.url) ?? "",
        alt: bannerImage?.alternativeText ?? "",
      }}
      imageDark={{
        src: resolveMediaUrl(bannerImageDark?.url) ?? "",
        alt:
          bannerImageDark?.alternativeText ??
          bannerImage?.alternativeText ??
          "",
      }}
      onClick={(type) => trackCTAAppDownload("footer", path, type)}
    />
  )
}
