"use client"
import { createInstance } from "i18next"
import { FC, ReactNode } from "react"
import { I18nextProvider } from "react-i18next"

import initTranslations, { Locale } from "@/utils/translations"

export type TranslationsProviderProps = {
  locale: Locale
  namespaces: string[]
  resources?: any
  children?: ReactNode
}

export const TranslationsProvider: FC<TranslationsProviderProps> = ({
  locale,
  namespaces,
  resources,
  children,
}) => {
  const i18n = createInstance()
  initTranslations(locale, namespaces, i18n, resources)

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
}
