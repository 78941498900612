"use client"
import { useTheme } from "@mui/material/styles"
import filter from "lodash-es/filter"
import isEqual from "lodash-es/isEqual"
import map from "lodash-es/map"
import { FC, useEffect, useState, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"

import { Link } from "@/components/common/link"
import {
  CookieBanner as CoreCookieBanner,
  CookieBannerSettingsCookie,
} from "@/components/core/cookie-banner"

import {
  getCookieConfig,
  COOKIE_CONFIG_TYPES,
  CookieConfig,
  CookieConfigCookie,
  CookieConfigType,
  CookieConfigTypes,
  CookieConfigUsedBy,
} from "@/config/cookies"
import { useCookieContext, CookieSettingCookie } from "@/context/cookies"
import { resolveDarkModeAsset } from "@/helpers/theme"
import type {
  ComponentLayoutLogo,
  ComponentMetaTracking,
  UploadFileEntityResponse,
} from "@/types/generated/strapi/graphql"

const I18N_BASE = "pages.provider.cookies"

export type CookieBannerCookie = {
  name: string
  value: string
}

export type CookieBannerProps = {
  appURL?: string
  logo?: ComponentLayoutLogo
  tracking?: ComponentMetaTracking
}

export const CookieBanner: FC<CookieBannerProps> = ({
  appURL = "",
  logo,
  tracking,
}) => {
  const { t } = useTranslation()
  const {
    cookieSettings = [],
    cookieDialogOpen,
    handleChangeCookieDialog,
    saveCookieSettings,
  } = useCookieContext()
  const theme = useTheme()
  const isDark = isEqual(theme.palette.mode, "dark")
  const [cookieConfig, setCookieConfig] = useState<CookieConfig>([])

  useEffect(() => {
    const config = getCookieConfig(tracking)
    setCookieConfig(config)
  }, [tracking])

  const getHost = useCallback(
    (host: string, usedBy?: CookieConfigUsedBy) =>
      usedBy === "third" ? host : appURL?.replace(/^https?:\/\//, ""),
    [appURL],
  )

  const mappedCookies = useMemo(
    () =>
      map(COOKIE_CONFIG_TYPES, (type: CookieConfigTypes) => {
        const setting = filter(
          cookieSettings,
          (e) => e.type === type.name,
        )?.[0] as CookieSettingCookie
        return {
          id: type.name,
          required: type.required,
          checked: setting?.enabled ?? type?.checked,
          title: t(`${I18N_BASE}.settings.types.${type.name}.title`),
          content: t(`${I18N_BASE}.settings.types.${type.name}.content`),
          details: map(
            filter(
              cookieConfig,
              (e: CookieConfigCookie) => e.type === type.name,
            ),
            ({ name, host, usedBy }: any) => ({
              name,
              host: getHost(host, usedBy),
              usedBy,
            }),
          ),
        } as CookieBannerSettingsCookie
      }),
    [t, cookieSettings, cookieConfig, getHost],
  ) as unknown as CookieBannerSettingsCookie[]

  const handleSaveCookieSettings = useCallback(
    async (
      settings?: CookieBannerSettingsCookie[] | null,
      action?: "granted" | "denied",
    ) => {
      switch (action) {
        case "granted":
          await saveCookieSettings(
            map(mappedCookies, (cookie) => ({
              type: cookie.id as CookieConfigType,
              enabled: true,
            })),
          )
          break
        case "denied":
          await saveCookieSettings(
            map(mappedCookies, (cookie) => ({
              type: cookie.id as CookieConfigType,
              enabled: !!cookie?.required,
            })),
          )
          break
        default:
          await saveCookieSettings(
            map(settings, (cookie) => ({
              type: cookie.id as CookieConfigType,
              enabled: cookie?.required || !!cookie.checked,
            })),
          )
      }
    },
    [mappedCookies, saveCookieSettings],
  )
  const logoDefaultAlt = logo?.logo.data?.attributes?.alternativeText as string
  return (
    <CoreCookieBanner
      open={cookieDialogOpen}
      acceptButton={{
        title: t(`${I18N_BASE}.accept`),
        onClick: (cookies) => handleSaveCookieSettings(cookies, "granted"),
      }}
      content={() => (
        <>
          {t(`${I18N_BASE}.content`)} {t(`${I18N_BASE}.link1`)}
          <Link href="#" target="_blank">
            {t(`${I18N_BASE}.link2`)}
          </Link>
          {t(`${I18N_BASE}.link3`)}
        </>
      )}
      rejectButton={{
        title: t(`${I18N_BASE}.reject`),
        onClick: (cookies) => handleSaveCookieSettings(cookies, "denied"),
      }}
      settings={{
        logo: {
          src: resolveDarkModeAsset(
            theme,
            logo?.logo as UploadFileEntityResponse,
            logo?.logoDark,
          ),
          alt: isDark
            ? (logo?.logoDark?.data?.attributes?.alternativeText ??
              logoDefaultAlt)
            : logoDefaultAlt,
        },
        content: t(`${I18N_BASE}.settings.content`),
        title: t(`${I18N_BASE}.settings.title`),
        linkText: t(`${I18N_BASE}.settings.link`),
        detailOptions: {
          subTitle: t(`${I18N_BASE}.settings.details.subTitle`),
          accordionDefaultTitle: t(
            `${I18N_BASE}.settings.details.accordion.defaultTitle`,
          ),
          backIcon: {
            "aria-label": t(`${I18N_BASE}.settings.details.backIcon.ariaLabel`),
          },
          noDataMsg: t(`${I18N_BASE}.settings.details.noData`),
        },
        cookies: mappedCookies,
        acceptButton: {
          title: t(`${I18N_BASE}.settings.accept`),
        },
        settingsButton: {
          title: t(`${I18N_BASE}.settings.save`),
        },
      }}
      settingsButton={{
        title: t(`${I18N_BASE}.setting`),
        onClick: (cookies) => handleSaveCookieSettings(cookies),
      }}
      onClose={() => handleChangeCookieDialog(false)}
    />
  )
}
