"use client"
import { usePathname } from "next/navigation"

import styles from "./BreadCrumb.module.scss"

import { Breadcrumb, BreadcrumbProps } from "@/components/core/breadcrumb"

import { create } from "@/helpers/bem"
import { trackPageChange } from "@/helpers/tracking"

const bem = create(styles, "BreadCrumb")

export type BreadCrumbProps = BreadcrumbProps

export const BreadCrumb = (props: BreadCrumbProps) => {
  const path = usePathname()
  return (
    <Breadcrumb
      {...props}
      capitalizeLinks
      className={bem()}
      onClick={(name, slug) =>
        trackPageChange("breadcrumb", path, slug ?? "", name)
      }
    />
  )
}
