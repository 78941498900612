import i18next from "i18next"
import isEmpty from "lodash/isEmpty"

import { FieldType, InputValidateEvent } from "./Input"

const isValidEmail = (email) => {
  const regex =
    /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x21\x23-\x5b\x5d-\x7e]|\\[\x21-\x7e])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z]{2,}|(?:\[(?:(?:2(?:5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(?:2(?:5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x21-\x5a\x53-\x7e]|\\[\x21-\x7e])+)])$/i
  return regex.test(email)
}

export const handleValidation = (
  type: FieldType,
  name: string,
  value?: string,
  required?: boolean,
  onValidate?: InputValidateEvent,
) => {
  if (!onValidate) return
  if (isEmpty(value) && required) {
    return onValidate(
      name,
      i18next.t("components.common.input.validations.required") ??
        "This field is required!",
    )
  }
  if (isEmpty(value)) return onValidate(name)
  switch (type) {
    case "email": {
      if (!isValidEmail(value))
        return onValidate(
          name,
          i18next.t("components.common.input.validations.email") ??
            "This is not a valid email!",
        )
      return onValidate(name)
    }
    default:
      return onValidate(name)
  }
}
