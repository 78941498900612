import { VIEWED_ARTICLES_COOKIE } from "@/app/actions/articles/constants"
import type { ComponentMetaTracking } from "@/types/generated/strapi/graphql"

// Custom cookies (self)

export const COOKIE_CONFIG_TYPES = [
  {
    name: "essential",
    required: true,
  },
  // Currently not in use
  // {
  //   name: "performance",
  // },
  {
    name: "analytics_storage",
  },
  {
    name: "ad_storage",
  },
  {
    name: "ad_user_data",
  },
  {
    name: "ad_personalization",
  },
] as const

export type CookieConfigType = (typeof COOKIE_CONFIG_TYPES)[number]["name"]

export type CookieConfigTypes = {
  name: CookieConfigType
  required?: boolean
  checked?: boolean
}

export type CookieConfigUsedBy = "self" | "third"

export type CookieConfigCookie =
  | {
      usedBy: "third"
      type: CookieConfigType
      name: string
      host: string
    }
  | {
      usedBy: "self"
      type: CookieConfigType
      name: string
    }

export type CookieConfig = CookieConfigCookie[]

export const getCookieConfig = (
  tracking?: ComponentMetaTracking,
): CookieConfig => {
  const trackingGoogle = tracking?.google
  let customConfig = [] as CookieConfig
  if (trackingGoogle?.GTMId) {
    customConfig = [
      ...customConfig,
      {
        type: "analytics_storage",
        name: "_ga",
        usedBy: "self",
      },
      {
        type: "ad_storage",
        name: `${trackingGoogle?.GTMId.replace("GTM-", "_ga_")}`,
        usedBy: "self",
      },
      {
        type: "analytics_storage",
        name: "ar_debug",
        host: ".region1.google-analytics.com",
        usedBy: "third",
      },
    ]
  }
  return [
    {
      type: "essential",
      name: `${VIEWED_ARTICLES_COOKIE}_*`,
      usedBy: "self",
    },
    ...customConfig,
  ]
}
