"use client"
import { useTheme } from "@mui/material/styles"
import filter from "lodash-es/filter"
import groupBy from "lodash-es/groupBy"
import isEqual from "lodash-es/isEqual"
import map from "lodash-es/map"
import React, { ReactNode, useState, useMemo, useCallback } from "react"

import { Accordion } from "../../common/accordion"
import { Headline, HeadlineProps } from "../../common/headline"
import { Icon } from "../../common/icon"

import styles from "./CBSettingsDetail.module.scss"
import type {
  CookieBannerSettingsDetailProps,
  CookieBannerSettingsDetailItem,
  CookieBannerSettingsDetailAccordionItems,
  CookieBannerSettingsDetailItemUsedBy,
} from "./CookieBanner.model"

import { create } from "@/helpers/bem"

const bem = create(styles, "CBSettingsDetail")

export const CookieBannerSettingsDetail = ({
  variant = "primary",
  title,
  titleOptions,
  subTitle,
  accordionSubTitle,
  backIcon,
  accordionDefaultTitle,
  noDataMsg,
  items = [],
  onClose,
}: CookieBannerSettingsDetailProps) => {
  const theme = useTheme()
  const [expanded, setExpanded] = useState<number | null>(null)
  const modifier = useMemo(
    () => ({
      [theme.palette.mode]: true,
      [variant]: !!variant,
      [`${theme.palette.mode}--${variant}`]: !!variant,
    }),
    [theme, variant],
  )

  const renderAccordionHeader = useCallback(
    (title: string, titleOptions?: HeadlineProps): ReactNode => (
      <div>
        <Headline size="xs" type="h3" variant="inherit" {...titleOptions}>
          {title}
        </Headline>
        <span className={bem("accordion__meta", modifier)}>
          {accordionSubTitle}
        </span>
      </div>
    ),
    [modifier, accordionSubTitle],
  )

  const renderAccordionContentListText = useCallback(
    (content: string): ReactNode => <span>{content}</span>,
    [],
  )

  const renderAccordionContentListContent = useCallback(
    (name: string, value: string): ReactNode =>
      name &&
      value && (
        <div className={bem("list__item__content")}>
          {renderAccordionContentListText(name)}
          {renderAccordionContentListText(value)}
        </div>
      ),
    [renderAccordionContentListText],
  )

  const renderAccordionContent = useCallback(
    (listItems): ReactNode => (
      <ul className={bem("list")}>
        {map(
          listItems,
          (item: CookieBannerSettingsDetailItem, i: number): ReactNode => (
            <li key={`list-item-${i}`} className={bem("list__item", modifier)}>
              {renderAccordionContentListContent(
                "Cookie Name",
                item?.name ?? "",
              )}
              {renderAccordionContentListContent("Host", item?.host ?? "")}
              {renderAccordionContentListContent("Type", item?.usedBy ?? "")}
            </li>
          ),
        )}
      </ul>
    ),
    [modifier, renderAccordionContentListContent],
  )

  const formatAccItems = useCallback(
    (items: CookieBannerSettingsDetailAccordionItems) =>
      map(items, (item) => {
        const { titleOptions, listItems, ...rest } = item
        return {
          renderHeader: () => renderAccordionHeader(item.title, titleOptions),
          renderContent: () => renderAccordionContent(listItems),
          ...rest,
        }
      }),
    [renderAccordionHeader, renderAccordionContent],
  )

  const filterByUse = useCallback(
    (usedBy: CookieBannerSettingsDetailItemUsedBy) =>
      filter(items, (e: CookieBannerSettingsDetailItem) =>
        isEqual(e.usedBy, usedBy),
      ),
    [items],
  )

  const formatedItems = useMemo(() => {
    const groupedThirdParties = groupBy(filterByUse("third"), "host")
    return [
      ...formatAccItems([
        {
          title: accordionDefaultTitle ?? "First Party Cookies",
          listItems: filterByUse("self"),
        },
      ]),
      ...formatAccItems(
        map(Object.keys(groupedThirdParties), (host) => ({
          title: host,
          listItems: groupedThirdParties[host],
        })) as CookieBannerSettingsDetailAccordionItems,
      ),
    ]
  }, [accordionDefaultTitle, formatAccItems, filterByUse])
  return (
    <div className={bem()}>
      <div className={bem("header")}>
        <Icon
          aria-label="Back to cookie list"
          className={bem("header__icon")}
          color={theme.palette?.inherit.main}
          name="ArrowLeftCircle"
          role="button"
          size="sm"
          onClick={onClose}
          {...backIcon}
        />
        {title ||
          (subTitle && (
            <div>
              {subTitle && (
                <Headline
                  className={bem("sub_headline", undefined)}
                  size="xs"
                  type="h3"
                  variant="primary"
                >
                  {subTitle}
                </Headline>
              )}
              {title && (
                <Headline
                  size="sm"
                  type="h2"
                  variant="inherit"
                  {...titleOptions}
                >
                  {title}
                </Headline>
              )}
            </div>
          ))}
      </div>
      {items.length > 0 ? (
        <Accordion
          expanded={expanded}
          id="cookie-list"
          items={formatedItems}
          onChange={(index) => setExpanded(index)}
        />
      ) : (
        <p className={bem("noData")}>
          {noDataMsg ??
            "It seems that no cookies are defined in this area, so this area has no impact on cookies."}
        </p>
      )}
    </div>
  )
}
