"use client"

import { useReportWebVitals } from "next/web-vitals"

import { IS_PRODUCTION } from "@/utils/enviroment"

export function WebVitals() {
  useReportWebVitals((metric) => {
    // TODO: Implement logic in production to send metrics to GA
    if (!IS_PRODUCTION) {
      console.log(metric)
    }
  })
}
