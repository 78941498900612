"use client"
import map from "lodash-es/map"
import React, {
  FC,
  memo,
  useState,
  useCallback,
  FormHTMLAttributes,
} from "react"
import { useFormStatus } from "react-dom"

import { Button, ButtonColor, ButtonProps } from "../button"
import { Grid, GridRow } from "../grid"
import { Headline } from "../headline"
import { Input, InputColor, InputProps } from "../input"
import { Select, SelectColor, SelectProps } from "../select"

import styles from "./Form.module.scss"

import { create } from "@/helpers/bem"

const bem = create(styles, "Form")

export type FormVariants =
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning"

export type FormFieldInput = {
  fieldType: "input"
} & InputProps

export type FormFieldSelect = {
  fieldType: "select"
} & SelectProps

export type FormField = FormFieldInput | FormFieldSelect

export type FormMessages =
  | {
      message: string
      errors?: any
    }
  | {
      message?: string
      errors: any
    }

export type FormProps = {
  locale?: string
  variant?: Omit<FormVariants, "white">
  fields: FormField[]
  messages?: FormMessages
  button: ButtonProps
  onChangeForm?: (field: FormField, value: unknown) => void
  onValidateForm?: (field: FormField, err?: string) => void
} & FormHTMLAttributes<HTMLFormElement>

function SubmitButton(props: ButtonProps) {
  const { pending } = useFormStatus()
  return <Button {...props} loading={pending} />
}

export const Form: FC<FormProps> = memo(
  ({
    variant = "primary",
    locale,
    className,
    fields,
    messages,
    button,
    onChangeForm,
    onValidateForm,
    ...props
  }) => {
    const [hp, setHp] = useState("")

    const renderField = useCallback(
      (field: FormField) => {
        const { fieldType, ...rest } = field
        switch (fieldType) {
          case "select":
            return (
              <Select
                color={variant as SelectColor}
                {...(rest as SelectProps)}
                onChange={(e) => {
                  onChangeForm?.(field, e.target.value)
                }}
              />
            )
          case "input":
            return (
              <Input
                color={variant as InputColor}
                onChange={(e) => onChangeForm?.(field, e.target.value)}
                onValidate={(_, err) => onValidateForm?.(field, err)}
                {...(rest as InputProps)}
              />
            )
          default:
            return null
        }
      },
      [variant, onChangeForm, onValidateForm],
    )

    return (
      <form {...props} role="form">
        <Grid spacing={1}>
          {map(fields, (field, i) => (
            <GridRow key={`form-input-${i}`} sm={6} xs={12}>
              {renderField(field)}
            </GridRow>
          ))}
          {renderField({
            fieldType: "input",
            name: "hp",
            className: bem("hp"),
            value: hp,
            onChange: (e) => setHp(e?.target?.value),
          })}
          <GridRow xs={12}>
            {messages?.message && (
              <p aria-live="polite" className={bem("success")}>
                {messages?.message}
              </p>
            )}
            {messages?.errors &&
              map(Object.keys(messages.errors), (key, a) => (
                <div
                  key={`form-error-${a}`}
                  className={bem("errors")}
                  role="alert"
                >
                  <Headline size="xs" type="h5" variant="error">
                    {key?.charAt(0)?.toUpperCase() + key?.slice(1)}
                  </Headline>
                  <ul className={bem("errors__list")}>
                    {map(messages.errors?.[key], (err, b) => (
                      <li
                        key={`form-error-${a}-${b}`}
                        aria-atomic="true"
                        aria-live="assertive"
                        className={bem("errors__list__item")}
                      >
                        {err}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            {hp?.length <= 0 && (
              <SubmitButton
                fullWidth
                color={variant as ButtonColor}
                type="submit"
                {...button}
              />
            )}
          </GridRow>
        </Grid>
      </form>
    )
  },
)

Form.displayName = "Form"
