import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/home/runner/work/mymatey-website/mymatey-website/frontend/node_modules/.pnpm/@apollo+client-react-streaming@0.11.2_@apollo+client@3.10.8_@types+react@18.3.3_graphql-ws@5._credyggk7uk5hz3idyxrqddafq/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/node_modules/.pnpm/@mui+material@5.16.1_@emotion+react@11.11.4_@types+react@18.3.3_react@18.3.1__@emotion+styled_qzfubmdmvtw77xu2jxyjjsp7w4/node_modules/@mui/material/Skeleton/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/home/runner/work/mymatey-website/mymatey-website/frontend/node_modules/.pnpm/@vercel+analytics@1.3.1_next@14.2.5_@babel+core@7.24.9_babel-plugin-macros@3.1.0_react-dom@18_6nczdoknnz6ssqqwwaatx2pt7i/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.9_babel-plugin-macros@3.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.8/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.9_babel-plugin-macros@3.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.8/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.9_babel-plugin-macros@3.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.8/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/src/app/[lang]/components/BreadCrumb/BreadCrumb.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/src/app/[lang]/components/CookieBanner/CookieBanner.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/src/app/[lang]/components/ErrorBoundary/BugsnagErrorBoundary.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/src/app/[lang]/components/Footer/FooterContainer.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/src/app/[lang]/components/FooterPromoBanner/FooterPromoBanner.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/src/app/[lang]/components/Header/Header.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/src/app/[lang]/components/Header/HeaderNavbar.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/src/app/[lang]/components/Tracking/Tracking.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/src/app/[lang]/components/TranslationsProvider/TranslationsProvider.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/src/app/[lang]/components/WebVitals/WebVitals.ts");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/src/app/[lang]/layout.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/src/app/[lang]/layouts/DefaultLayout.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/src/components/core/themeRegistry/ThemeRegistry.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ApolloWrapper"] */ "/home/runner/work/mymatey-website/mymatey-website/frontend/src/config/apollo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CookieProvider"] */ "/home/runner/work/mymatey-website/mymatey-website/frontend/src/context/cookies.tsx");
