import dynamic from "next/dynamic"
import { FC } from "react"

import styles from "./Logo.module.scss"

import type { NextImageProps } from "@/components/common/image"
import { Link } from "@/components/common/link"
import { Skeleton } from "@/components/common/skeleton"

const NextImage = dynamic(
  () =>
    import("@/components/common/image").then(({ NextImage }) => ({
      default: NextImage,
    })),
  { loading: () => <Skeleton height={30} /> },
)

import { create } from "@/helpers/bem"

const bem = create(styles, "Logo")

export type LogoProps = Omit<NextImageProps, "src" | "alt"> & {
  variant?: "primary" | "secondary"
  ariaLabel?: string
  disabled?: boolean
  src?: string | null
  alt?: string
}

export const Logo: FC<LogoProps> = ({
  variant = "primary",
  src,
  alt,
  disabled,
  ariaLabel,
  className,
  ...props
}) => {
  const renderLogo = () =>
    src && (
      <NextImage
        fill
        priority
        src={src}
        {...props}
        alt={alt ?? "mymatey logo"}
        className={bem("image")}
      />
    )

  if (disabled) {
    return <div className={className}>{renderLogo()}</div>
  }
  return (
    <Link
      aria-label={ariaLabel ?? "mymatey logo - Back to homepage"}
      className={className}
      disabled={disabled}
      href="/"
      role="img"
    >
      {renderLogo()}
    </Link>
  )
}

Logo.displayName = "Logo"
