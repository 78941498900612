"use client"
import React from "react"

import { BugsnagErrorBoundary as CoreBugsnagErrorBoundary } from "@/config/bugsnag"

export const BugsnagErrorBoundary = ({ children }) => {
  if (!CoreBugsnagErrorBoundary) {
    return <>{children}</>
  }
  return <CoreBugsnagErrorBoundary>{children}</CoreBugsnagErrorBoundary>
}
