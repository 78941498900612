export const getLanguage = (lang?: string) => {
  switch (lang) {
    case "de":
      return {
        flag: "&#x1F1E9;&#x1F1EA;",
      }
    default:
      return {
        flag: "&#x1F1EC;&#x1F1E7;",
      }
  }
}
