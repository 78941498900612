"use client"
import { useTheme } from "@mui/material/styles"
import isEqual from "lodash-es/isEqual"
import map from "lodash-es/map"
import size from "lodash-es/size"
import { FC, ReactNode, useMemo, useCallback, isValidElement } from "react"
import Markdown from "react-markdown"
import remarkBreaks from "remark-breaks"
import remarkGfm from "remark-gfm"

import styles from "./Footer.module.scss"

import { Button, ButtonColor } from "@/components/common/button"
import { FormProps } from "@/components/common/form"
import { Grid, GridRow } from "@/components/common/grid"
import { Headline, HeadlineProps } from "@/components/common/headline"
import { IconName } from "@/components/common/icon"
import { Link, LinkProps } from "@/components/common/link"
import { List, ListProps } from "@/components/common/list"

import { Logo, LogoProps } from "@/components/core/logo"

import { create } from "@/helpers/bem"
import { Variants } from "@/types/variants"

const bem = create(styles, "Footer")

export type FooterInfoLink = LinkProps

export type FooterInfoLogo = LogoProps | ReactNode

export type FooterFormHeadline = HeadlineProps

export type FooterForm = {
  headline?: FooterFormHeadline
} & FormProps

export type FooterInfo = {
  logo?: FooterInfoLogo
  logoDark?: FooterInfoLogo
  description?: string
  copyright: string
  links?: FooterInfoLink[]
}

export type FooterNavigation = {
  headline: string
} & ListProps

export type FooterSocialLinkColor = ButtonColor

export type FooterSocialLink = {
  key: number | string
  icon: IconName
  variant?: FooterSocialLinkColor
  redirect?: LinkProps
}

export type FooterSocial = {
  headline: string
  description?: string
  links?: FooterSocialLink[]
}

export type FooterProps = {
  variant?: "primary" | "secondary"
  info?: FooterInfo
  navigation?: FooterNavigation[]
  social?: FooterSocial
  children?: ReactNode
  onClickLink?: (parent?: string, name?: string, slug?: string) => void
  onClickSocialLink?: (type?: IconName) => void
}

export const Footer: FC<FooterProps> = ({
  variant = "primary",
  info,
  navigation,
  social,
  children,
  onClickLink,
  onClickSocialLink,
}) => {
  const theme = useTheme()
  const themeMode = theme.palette.mode
  const isDark = isEqual(themeMode, "dark")
  const themeModifier = useMemo(
    () => ({
      [themeMode]: true,
    }),
    [themeMode],
  )

  const renderLogo = useCallback(() => {
    const logo = info?.logo
    const logoDark = info?.logoDark
    if (!logo) {
      return null
    }
    if (isValidElement(logo)) {
      return (
        <Link className={bem("logo")} href="/">
          {(!isDark ? logo : (logoDark ?? logo)) as ReactNode}
        </Link>
      )
    }
    let logoSrc = logo
    if (isDark && logoDark) {
      logoSrc = logoDark
    }
    const { alt, ariaLabel, src } = logoSrc as LogoProps
    return (
      <Logo
        alt={alt ?? "Logo"}
        ariaLabel={ariaLabel ?? "Back to homepage"}
        className={bem("logo")}
        src={src}
        variant={variant}
      />
    )
  }, [isDark, info?.logo, info?.logoDark, variant])

  const renderInfo = useCallback(() => {
    if (!info) {
      return null
    }
    const { description, copyright, links } = info
    return (
      <div className={bem("col")}>
        {renderLogo()}
        {description && (
          <Markdown
            remarkPlugins={[remarkGfm, remarkBreaks]}
            components={{
              p: (props) => (
                <p className={bem("desc", themeModifier)}>{props?.children}</p>
              ),
            }}
          >
            {description}
          </Markdown>
        )}
        <span className={bem("copyright", themeModifier)}>
          &copy;{new Date().getFullYear()} {copyright}
        </span>
        {!isEqual(size(links), 0)
          ? map(links, (link) => (
              <Link
                key={link.id}
                {...link}
                className={bem("logo__link", themeModifier)}
                variant={link?.variant ?? variant}
                onClick={(e) => {
                  link?.href && onClickLink?.("info", link?.title, link?.href)
                  link?.onClick?.(e)
                }}
              />
            ))
          : null}
      </div>
    )
  }, [variant, info, themeModifier, renderLogo, onClickLink])

  const renderNavigation = useCallback(() => {
    if (isEqual(size(navigation), 0)) {
      return null
    }
    return (
      <ul className={bem("nav")}>
        {map(navigation, ({ headline, ...props }) => (
          <li key={headline} className={bem("nav__item")}>
            <Headline className={bem("headline")} size="sm" type="h3">
              {headline}
            </Headline>
            <List
              {...props}
              variant={props?.variant}
              items={map(props.items, (item) => ({
                ...item,
                variant: item?.variant as Variants | undefined,
                redirect: {
                  ...item?.redirect,
                  href: item?.redirect?.href ?? "",
                  ariaLabel: `Link to ${item?.icon}`,
                  className: bem("nav__link"),
                  onClick: () =>
                    onClickLink?.(headline, item?.title, item?.redirect?.href),
                },
              }))}
            />
          </li>
        ))}
      </ul>
    )
  }, [navigation, onClickLink])

  const renderSocial = useCallback(() => {
    if (!social) return null
    const { headline, description, links } = social
    return (
      <div className={bem("col")}>
        <Headline className={bem("headline")} size="sm" type="h3">
          {headline}
        </Headline>
        {description && (
          <Markdown
            remarkPlugins={[remarkGfm, remarkBreaks]}
            components={{
              p: (props) => (
                <p className={bem("desc", themeModifier)}>{props?.children}</p>
              ),
            }}
          >
            {description}
          </Markdown>
        )}
        {!isEqual(size(links), 0) && (
          <ul className={bem("social__links")}>
            {map(links, (props) => (
              <li
                key={`social-link-${props.key}`}
                className={bem("social__links__item")}
              >
                <Button
                  aria-label={props?.icon}
                  color={props?.variant ?? variant}
                  redirect={props.redirect}
                  variant="contained"
                  iconProps={{
                    name: props?.icon,
                    size: "sm",
                  }}
                  onClick={() => onClickSocialLink?.(props?.icon)}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    )
  }, [variant, social, themeModifier, onClickSocialLink])

  return (
    <footer
      className={bem(undefined, {
        ...themeModifier,
      })}
    >
      {children}
      <Grid className={bem("content")} spacing={0}>
        <GridRow lg={2} xs={12}>
          {renderInfo()}
        </GridRow>
        <GridRow lg={8} xs={12}>
          <nav className={bem("col")}>{renderNavigation()}</nav>
        </GridRow>
        <GridRow lg={2} xs={12}>
          {renderSocial()}
        </GridRow>
      </Grid>
    </footer>
  )
}
