"use client"
import { usePathname } from "next/navigation"
import type { FC } from "react"
import { useTranslation } from "react-i18next"

import { FooterForm } from "./FooterForm"

import type { FooterFormData } from "./FooterForm"

import type { FooterProps } from "@/components/core/footer"
import { Footer as CommonFooter } from "@/components/core/footer"

import { useCookieContext } from "@/context/cookies"
import {
  trackCookieDialog,
  trackPageChange,
  trackSocialRedirect,
} from "@/helpers/tracking"

export type FooterContainerProps = FooterProps & {
  form?: FooterFormData
}

export const FooterContainer: FC<FooterContainerProps> = ({
  form,
  info,
  ...props
}) => {
  const { t } = useTranslation()
  const path = usePathname()
  const { handleChangeCookieDialog } = useCookieContext()
  const formatedProps = {
    ...props,
    info: {
      ...(info ?? {}),
      links: [
        ...(info?.links ?? []),
        {
          id: "cookie-preferences",
          variant: "inherit",
          children: t("pages.components.footer.cookiePreferences"),
          onClick: () => {
            trackCookieDialog("footer", true)
            handleChangeCookieDialog(true)
          },
        },
      ],
    },
  } as FooterProps
  return (
    <CommonFooter
      {...formatedProps}
      onClickSocialLink={(type) => trackSocialRedirect(type ?? "", path)}
      onClickLink={(parent, name, slug) =>
        trackPageChange(
          parent ? `footer_${parent}` : "",
          path,
          slug ?? "not_defined",
          name,
        )
      }
    >
      <FooterForm formData={form as FooterFormData} />
    </CommonFooter>
  )
}
