"use client"
import MatChip, { ChipProps as MUIChipProps } from "@mui/material/Chip"
import { FC, memo } from "react"

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    inherit: true
    teritary: true
    white: true
  }
}

export type ChipProps = MUIChipProps

export const Chip: FC<ChipProps> = memo(
  ({ className, variant = "filled", color = "primary", ...props }) => (
    <MatChip {...props} color={color} variant={variant} />
  ),
)

Chip.displayName = "Chip"
