"use client"
import { useTheme } from "@mui/material/styles"
import every from "lodash-es/every"
import isUndefined from "lodash-es/isUndefined"
import values from "lodash-es/values"
import { usePathname } from "next/navigation"
import type { FC } from "react"
import { useState, useMemo, useCallback } from "react"
import { useFormState } from "react-dom"
import { useTranslation } from "react-i18next"

import styles from "./FooterForm.module.scss"

import { createContact } from "@/app/actions/mailchimp/createContact"
import { Animated } from "@/components/common/animated"
import type { ButtonProps } from "@/components/common/button"
import { Form } from "@/components/common/form"
import type { FormMessages } from "@/components/common/form"
import type { FormField } from "@/components/common/form"
import { Headline } from "@/components/common/headline"
import type { HeadlineProps } from "@/components/common/headline"

import { create } from "@/helpers/bem"
import { trackCTANewsletterSubscription } from "@/helpers/tracking"
import type { ComponentLayoutFooterFormInput } from "@/types/generated/strapi/graphql"

import type { Locale } from "@/utils/translations"

const bem = create(styles, "FooterForm")

export type FormState = FormMessages

const initialState: FormState = {
  message: "",
}

export type FooterFormData = ComponentLayoutFooterFormInput

export type FooterFormProps = {
  formData?: FooterFormData
}

export const FooterForm: FC<FooterFormProps> = ({ formData }) => {
  const { i18n } = useTranslation()
  const locale = i18n.language
  const theme = useTheme()
  const path = usePathname()
  const themeMode = theme.palette.mode
  const [form, setForm] = useState({
    name: "",
    email: "",
  })
  const [formErrors, setFormErrors] = useState({
    name: undefined,
    email: undefined,
  })

  const handleSubmit = useCallback(
    async (state: FormState, payload: FormData) => {
      trackCTANewsletterSubscription("footer", path)
      return createContact(locale as Locale, state, payload)
    },
    [locale, path],
  )

  const [state, action] = useFormState(handleSubmit, initialState)
  const handleValidateForm = useCallback((field: FormField, err?: string) => {
    setFormErrors((el) => ({
      ...el,
      [field.name as string]: err,
    }))
  }, [])

  const handleChangeForm = useCallback((field: FormField, value: unknown) => {
    setForm((el) => ({
      ...el,
      [field.name as string]: value,
    }))
  }, [])

  const formatedForm = useMemo(
    () =>
      [
        {
          fieldType: "input",
          type: "text",
          required: true,
          label: "Name",
          name: "name",
          value: form?.name,
          max: 255,
          hideLegend: true,
          errorText: formErrors?.name,
        },
        {
          fieldType: "input",
          type: "email",
          required: true,
          label: "Email",
          name: "email",
          max: 255,
          hideLegend: true,
          value: form?.email,
          errorText: formErrors?.email,
        },
      ] as FormField[],
    [form, formErrors],
  )
  const formIsValid = useMemo(
    () => every(values(formErrors), isUndefined),
    [formErrors],
  )
  return (
    formatedForm &&
    formData?.button && (
      <div
        className={bem(undefined, {
          [themeMode]: true,
        })}
      >
        <Animated className={bem("form")}>
          {formData?.title && (
            <Headline
              className={bem("form__headline")}
              size="md"
              type="h3"
              {...(formData?.titleOptions as HeadlineProps)}
            >
              {formData?.title}
            </Headline>
          )}
          <Form
            action={action}
            fields={formatedForm}
            locale={locale}
            messages={state}
            button={
              {
                ...formData?.button,
                color: formData?.button?.color ?? "primary",
                title: formData?.button?.label as string,
                disabled: !formIsValid,
              } as ButtonProps
            }
            onChangeForm={handleChangeForm}
            onValidateForm={handleValidateForm}
          />
        </Animated>
      </div>
    )
  )
}
