"use client"
import useMediaQuery from "@mui/material/useMediaQuery"
import isUndefined from "lodash/isUndefined"

import { usePathname } from "next/navigation"
import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react"

import { trackDarkModeChange } from "@/helpers/tracking"

interface ThemeContextType {
  darkMode: boolean | undefined
  handleDarkMode: () => void
}

const ThemeCtx = createContext<ThemeContextType | undefined>(undefined)

export function ThemeCtxProvider({ children }: { children: ReactNode }) {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)")
  const pathname = usePathname()
  const [darkMode, setDarkMode] = useState<boolean | undefined>(prefersDarkMode)

  useEffect(() => {
    const theme = localStorage.getItem("theme")
    if (!isUndefined(theme)) {
      setDarkMode(theme === "dark")
      return
    }
    setDarkMode(prefersDarkMode)
  }, [prefersDarkMode])

  const handleDarkMode = () => {
    if (darkMode) {
      trackDarkModeChange("dark", "light", pathname)
      localStorage.setItem("theme", "light")
      setDarkMode(false)
    } else {
      trackDarkModeChange("light", "dark", pathname)
      localStorage.setItem("theme", "dark")
      setDarkMode(true)
    }
  }

  return (
    <ThemeCtx.Provider value={{ darkMode, handleDarkMode }}>
      {children}
    </ThemeCtx.Provider>
  )
}

export function useThemeMode(): ThemeContextType {
  const context = useContext(ThemeCtx)

  if (context === undefined) {
    throw new Error("useThemeMode must be used within a ThemeCtxProvider")
  }

  return context
}
