"use client"
import FormControl from "@mui/material/FormControl"
import InputLabel, { InputLabelProps } from "@mui/material/InputLabel"
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem"
import MUISelect, {
  SelectChangeEvent as MUISelectChangeEvent,
} from "@mui/material/Select"
import type { SelectProps as MUISelectProps } from "@mui/material/Select"
import { styled } from "@mui/material/styles"
import { useTheme } from "@mui/material/styles"
import isEqual from "lodash-es/isEqual"
import map from "lodash-es/map"
import size from "lodash-es/size"
import { FC, ReactNode } from "react"

import styles from "./Select.module.scss"

import { create } from "@/helpers/bem"
import { hexToRGB } from "@/helpers/hexToRGB"

const bem = create(styles, "Select")

export type SelectItem = MenuItemProps & {
  label?: string
  icon?: () => ReactNode
}

export type SelectChangeEvent<T> = MUISelectChangeEvent<T>

export type SelectColor =
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning"

export type SelectProps = Omit<MUISelectProps, "variant" | "color" | "name"> & {
  id: string
  name: string
  color?: SelectColor
  label: string
  hideLabel?: boolean
  labelProps?: InputLabelProps
  classNameSelect?: string
  items: SelectItem[]
  onChange: (event: SelectChangeEvent<unknown>, child: ReactNode) => void
}

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette?.inherit.main,
}))
const StyledSelect = styled(MUISelect)(({ theme, color = "primary" }) => ({
  color: theme.palette?.inherit.main,
  "& .MuiSelect-select": {
    paddingTop: 13,
    paddingBottom: 13,
    display: "flex",
    alignItems: "center",
  },
  "&.MuiOutlinedInput-root": {
    borderRadius: 15,
    "& fieldset": {
      paddingTop: 5,
      paddingBottom: 5,
      borderWidth: 0,
    },
    "&.Mui-focused .MuiSelect-select": {
      color: theme.palette?.[color].main,
    },
    "&.Mui-focused fieldset": {
      borderWidth: 0,
    },
  },
  "& .MuiSelect-icon": {
    color: theme.palette.inherit.main,
  },
}))
const StyledMenuItem = styled(MenuItem)(({ theme, color = "primary" }) => {
  const themeColor = theme.palette?.[color].main
  return {
    color: theme.palette?.inherit.main,
    "&:hover": {
      color: themeColor,
      backgroundColor: hexToRGB(themeColor, 0.04),
    },
    "&:focus": {
      backgroundColor: hexToRGB(themeColor, 0.2),
    },
    "&:hover:focus": {
      backgroundColor: hexToRGB(themeColor, 0.12),
    },
  }
})

export const Select: FC<SelectProps> = ({
  id,
  color = "primary",
  label,
  hideLabel,
  labelProps,
  className,
  classNameSelect,
  items,
  ...props
}) => {
  const theme = useTheme()
  const labelId = `${id}-label`
  if (isEqual(size(items), 0)) {
    return null
  }
  return (
    <FormControl
      fullWidth
      color={color}
      className={bem(
        undefined,
        {
          [theme.palette.mode]: true,
        },
        className,
      )}
    >
      {!hideLabel && (
        <StyledInputLabel {...labelProps} id={labelId}>
          {label}
        </StyledInputLabel>
      )}
      <StyledSelect
        aria-label={label}
        color={color}
        id={id}
        label={!hideLabel ? label : undefined}
        labelId={labelId}
        {...props}
        className={bem("select", undefined, classNameSelect)}
        variant="outlined"
        MenuProps={{
          PaperProps: {
            sx: {
              marginTop: "-15px",
              marginLeft: "-0.5px",
              paddingTop: "12px",
              borderRadius: "0 0 15px 15px",
              boxShadow: theme.shadows[2],
              backgroundColor: theme.palette.backgroundWhite.main,
              backgroundImage: "none",
              "& .MuiList-root": {
                paddingTop: 0,
                paddingBottom: 0,
              },
            },
          },
          ...props?.MenuProps,
        }}
      >
        {map(items, (item, i) => {
          const { icon, ...rest } = item
          return (
            <StyledMenuItem
              key={`${labelId}-item-${i}`}
              color={color}
              {...rest}
              aria-label={item?.label}
              className={bem("item", undefined, item?.className)}
            >
              {icon && icon?.()}
              {item?.label}
            </StyledMenuItem>
          )
        })}
      </StyledSelect>
    </FormControl>
  )
}
