"use client"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import findIndex from "lodash-es/findIndex"
import map from "lodash-es/map"
import React, {
  ReactNode,
  SyntheticEvent,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from "react"

import { Accordion, AccordionItem } from "../../common/accordion"
import { Button } from "../../common/button"
import { Chip } from "../../common/chip"
import { Drawer, renderDrawerCloseButton } from "../../common/drawer"
import { Headline } from "../../common/headline"
import { Switch } from "../../common/switch"
import { Logo } from "../logo"

import styles from "./CBSettings.module.scss"
import type {
  CookieBannerSettingsProps,
  CookieBannerSettingsCookie,
} from "./CookieBanner.model"

import { CookieBannerSettingsDetail } from "./CookieBannerSettingsDetail"

import { create } from "@/helpers/bem"

const bem = create(styles, "CBSettings")

export const CookieBannerSettings = ({
  variant = "primary",
  logo,
  title,
  titleOptions,
  content,
  cookies,
  linkText,
  detailOptions,
  cookiesDefaultChecked = false,
  acceptButton,
  settingsButton,
  rejectButton,
  onChangeCookie,
  onClose,
  ...props
}: CookieBannerSettingsProps) => {
  const theme = useTheme()
  const screenSmallerMd = useMediaQuery(theme.breakpoints.down("md"))
  const screenSmallerSm = useMediaQuery(theme.breakpoints.down("sm"))
  const [expanded, setExpanded] = useState<number | null>(null)
  const [detailId, setDetailId] = useState<string | null>(null)
  const [items, setItems] = useState<CookieBannerSettingsCookie[]>([])

  useEffect(() => {
    const mappedCookies = map(cookies, (cookie) => ({
      ...cookie,
      checked: cookie?.checked ?? cookie?.required ?? cookiesDefaultChecked,
    }))
    setItems(mappedCookies)
  }, [cookies, cookiesDefaultChecked])

  const handleChangeSwitch = useCallback(
    (
      e: SyntheticEvent,
      checked: boolean,
      item?: CookieBannerSettingsCookie,
    ) => {
      setItems((prevItems) => {
        const index = items.findIndex((e) => e.id === item?.id)
        if (index < 0) return prevItems
        const updatedItems = [...prevItems]
        updatedItems[index] = {
          ...updatedItems[index],
          checked: checked ?? false,
        } as CookieBannerSettingsCookie
        return updatedItems
      })
      onChangeCookie?.(e, checked, item)
    },
    [items, setItems, onChangeCookie],
  )

  const renderAccordionHeader = useCallback(
    (item: CookieBannerSettingsCookie): ReactNode => {
      const { required, title, titleOptions, checked } = item
      return (
        <div className={bem("accordion__header")}>
          <Headline size="sm" type="h3" variant="inherit" {...titleOptions}>
            {title}
          </Headline>
          {required ? (
            <Chip label="Always active" size="small" variant="filled" />
          ) : (
            <Switch
              checked={checked}
              variant="primary"
              onChange={(e, checked) => handleChangeSwitch(e, checked, item)}
            />
          )}
        </div>
      )
    },
    [handleChangeSwitch],
  )

  const handleChangeDetailId = useCallback(
    (id?: string | null) => setDetailId(id ?? null),
    [setDetailId],
  )

  const renderAccordionContent = useCallback(
    (item: CookieBannerSettingsCookie): ReactNode => (
      <p className={bem("accordion__content")}>
        {item?.content}
        <span
          className={bem("accordion__link")}
          tabIndex={0}
          onClick={() => handleChangeDetailId(item.id)}
        >
          {linkText}
        </span>
      </p>
    ),
    [linkText, handleChangeDetailId],
  )

  const formatedItems = useMemo(
    () =>
      map(
        items,
        (item: CookieBannerSettingsCookie) =>
          ({
            ...item,
            renderHeader: () => renderAccordionHeader(item),
            renderContent: () => renderAccordionContent(item),
          }) as AccordionItem,
      ),
    [items, renderAccordionHeader, renderAccordionContent],
  )
  const detailIndex = useMemo(
    () => findIndex(items, (e) => e.id === detailId),
    [items, detailId],
  )
  const buttonColor =
    variant === "white" || variant === "teritary" ? "inherit" : variant
  const paperWidth = screenSmallerMd
    ? screenSmallerSm
      ? "100%"
      : "80%"
    : "50%"
  return (
    <Drawer
      anchor="left"
      PaperProps={{
        sx: {
          height: "calc(100% - 73px)",
          width: paperWidth,
          backgroundColor: theme.palette.backgroundWhite.main,
        },
      }}
      renderHeader={() => (
        <div className={bem("header")}>
          <Logo
            disabled
            {...logo}
            className={bem("header__logo", undefined, logo?.className)}
          />
          {renderDrawerCloseButton(onClose)}
        </div>
      )}
      sx={{
        padding: theme.spacing(2),
      }}
      onClose={onClose}
      {...props}
    >
      {detailIndex >= 0 ? (
        <CookieBannerSettingsDetail
          {...detailOptions}
          accordionSubTitle={linkText ?? detailOptions?.accordionSubTitle}
          items={cookies[detailIndex]?.details ?? []}
          title={cookies[detailIndex]?.title}
          onClose={() => handleChangeDetailId(null)}
        />
      ) : (
        <div className={bem("content")}>
          {content && <p className={bem("paragraph")}>{content}</p>}
          <Button
            className={bem("button")}
            color={buttonColor}
            size="medium"
            variant="contained"
            {...acceptButton}
            onClick={(e) => {
              acceptButton?.onClick?.(items)
              handleChangeDetailId(null)
              onClose(e)
            }}
          />
          {title && (
            <Headline
              className={bem("headline")}
              type="h2"
              variant="inherit"
              {...titleOptions}
            >
              {title}
            </Headline>
          )}
          <Accordion
            expanded={expanded}
            id="ck-list"
            items={formatedItems}
            onChange={(index) => setExpanded(index)}
          />
        </div>
      )}
      <div
        style={{ width: paperWidth }}
        className={bem("footer", {
          [theme.palette.mode]: true,
        })}
      >
        <Button
          className={bem("button")}
          color="error"
          size="medium"
          variant="outlined"
          {...rejectButton}
          onClick={(e) => {
            rejectButton?.onClick?.(items)
            handleChangeDetailId(null)
            onClose(e)
          }}
        />
        <Button
          className={bem("button")}
          color={buttonColor}
          size="medium"
          variant="outlined"
          {...settingsButton}
          onClick={(e) => {
            settingsButton?.onClick?.(items)
            handleChangeDetailId(null)
            onClose(e)
          }}
        />
      </div>
    </Drawer>
  )
}
