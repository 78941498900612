"use client"
import { useTheme } from "@mui/material/styles"
import styled from "@mui/material/styles/styled"
import MUITextField, { TextFieldProps } from "@mui/material/TextField"
import React, { FC, memo, ChangeEventHandler, InputHTMLAttributes } from "react"

import { create } from "src/helpers/bem"

import styles from "./Input.module.scss"
import { handleValidation } from "./InputValidation"

const bem = create(styles, "Input")

export type FieldType = Extract<
  InputHTMLAttributes<unknown>["type"],
  "text" | "email"
>

export type InputChangeEvent = ChangeEventHandler<
  HTMLTextAreaElement | HTMLInputElement
>
export type InputValidateEvent = (name: string, error?: string) => void
export type InputColor =
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning"

export type InputProps = {
  type?: FieldType
  color?: InputColor
  name: string
  min?: number
  max?: number
  hideLegend?: boolean
  errorText?: string
  onChange?: InputChangeEvent
  onValidate?: InputValidateEvent
} & Omit<TextFieldProps, "variant" | "color" | "name" | "onChange">

const StyledInput = styled(MUITextField)(({ theme }) => ({
  "& .MuiInputLabel-root": {
    color: theme.palette?.inherit.main,
    top: -3,

    "&.Mui-focused": {
      color: theme.palette?.inherit.main,
      top: 0,
    },
    "&.MuiFormLabel-filled": {
      top: 0,
    },
  },
  "& .MuiInputBase-input": {
    borderRadius: 15,
    backgroundColor: theme.palette.backgroundWhite.main,
    color: theme.palette?.inherit.main,
    paddingTop: 13,
    paddingBottom: 13,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: 15,
    borderWidth: 0,
    boxShadow: theme.shadows[1],
  },
  "& .MuiSelect-icon": {
    color: theme.palette.inherit.main,
  },
}))

export const Input: FC<InputProps> = memo(
  ({
    type = "text",
    color = "primary",
    name,
    min,
    max,
    className,
    required,
    hideLegend,
    errorText,
    helperText,
    value,
    onChange,
    onValidate,
    ...props
  }) => {
    const theme = useTheme()
    const modifier = {
      [theme.palette.mode]: !errorText,
    }
    return (
      <StyledInput
        color={color}
        {...props}
        fullWidth
        className={bem(undefined, undefined, className)}
        error={!!errorText}
        name={name}
        required={required}
        type="text"
        value={value ?? ""}
        variant="outlined"
        FormHelperTextProps={{
          classes: {
            root: bem("helperText__wrapper"),
          },
        }}
        helperText={
          errorText ||
          helperText ||
          (!!max &&
            !hideLegend &&
            !!value &&
            (value as string)?.toString()?.length > 0) ? (
            <span className={bem("helperText", modifier)}>
              <span className={bem("helperText__content")} role="alert">
                {errorText ?? helperText}
              </span>
              {!!max && !!value ? (
                <span className={bem("helperText__legend")}>
                  {(value as string)?.toString()?.length ?? 0} / {max}
                </span>
              ) : null}
            </span>
          ) : undefined
        }
        inputProps={{
          ...props.inputProps,
          minLength: min,
          maxLength: max,
        }}
        onChange={
          onChange &&
          ((e) => {
            handleValidation?.(
              type,
              name,
              e?.target?.value,
              required,
              onValidate,
            )
            onChange(e)
          })
        }
      />
    )
  },
)

Input.displayName = "Input"
