"use client"
import CssBaseline from "@mui/material/CssBaseline"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { ReactNode, useMemo, useEffect } from "react"

import NextAppDirEmotionCacheProvider from "./EmotionCache"

import { ThemeCtxProvider, useThemeMode } from "@/hooks/themeContext"
import { lightTheme, darkTheme } from "@/utils/theme"
import { emitThemeChange } from "@/utils/themeChangeEmitter"

export const ThemeRegistry = ({
  children,
  overwriteTheme,
}: {
  children: ReactNode
  overwriteTheme?: any
}) => {
  const { darkMode } = useThemeMode()
  let theme = useMemo(() => {
    return createTheme(darkMode ? darkTheme : lightTheme)
  }, [darkMode])

  useEffect(() => {
    emitThemeChange(darkMode ?? false)
  }, [darkMode])

  return (
    <NextAppDirEmotionCacheProvider options={{ key: "mui" }}>
      <ThemeProvider theme={overwriteTheme ?? theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </NextAppDirEmotionCacheProvider>
  )
}

export const CustomThemeProvider = ({
  children,
  theme,
}: {
  children: ReactNode
  theme?: any
}) => (
  <ThemeCtxProvider>
    <ThemeRegistry overwriteTheme={theme}>{children}</ThemeRegistry>
  </ThemeCtxProvider>
)
