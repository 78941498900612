import { createTheme } from "@mui/material/styles"
import type { Shadows } from "@mui/material/styles"

import { montserrat } from "./font"

export const lightTheme = createTheme({
  breakpoints: {
    values: {
      xs: 375,
      sm: 480,
      md: 960,
      lg: 1280,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: montserrat.style.fontFamily,
  },
  spacing: 16,
  palette: {
    mode: "light",
    inherit: {
      main: "#4d4d4d",
      contrastText: "#fff",
    },
    primary: {
      main: "#FFAC2D",
      contrastText: "#422A00",
    },
    primaryDark: {
      main: "#7A4C00",
      contrastText: "#FFF",
    },
    primaryLight: {
      main: "#FFD321",
      contrastText: "#4d4d4d",
    },
    secondary: {
      main: "#20E381",
      contrastText: "#073B21",
    },
    teritary: {
      main: "#10CCB8",
      contrastText: "#052E27",
    },
    gray: {
      main: "#7a7979",
    },
    darkGray: {
      main: "#444444",
    },
    lightGray: {
      main: "#a6a4a4",
    },
    white: {
      main: "#fff",
      contrastText: "#4d4d4d",
    },
    black: {
      main: "#000",
    },
    info: {
      main: "#7a7979",
    },
    warning: {
      main: "#f7b529",
    },
    error: {
      main: "#f0453a",
    },
    background: {
      default: "#FAFAFA",
    },
    backgroundLightGray: {
      main: "#F2F2F2",
    },
    backgroundWhite: {
      main: "#fcfcfc",
    },
  },
  gradiant: {
    inherit: `linear-gradient(#FFD321, #FFAC2D)`,
    primary: `linear-gradient(#FFD321, #FFAC2D)`,
    primaryBackground: `linear-gradient(#FFD321, #FFAC2D)`,
    secondary: "linear-gradient(#20E381, #20E381, #10CCB8)",
    secondaryBackground: "linear-gradient(#20E381, #10CCB8)",
    teritary: "linear-gradient(#20E381, #20E381, #10CCB8)",
    grayBackground: "linear-gradient(#999999, #c2c2c2)",
    lightGray: "linear-gradient(#dbdbdb, #e0e0e0, #ccc)",
    lightGrayBackground: "linear-gradient(#b3b3b3, #dbdbdb)",
    info: "linear-gradient(#ccc, #b8b6b6, #919090)",
    success: "linear-gradient(#20E381, #20E381, #10CCB8)",
    warning: `linear-gradient(#FFCC00, #cca300)`,
    error: "linear-gradient(#eb5550, #db4a46, #ff4444)",
    errorBackground: "linear-gradient(#ff5c57, #fc7672)",
  },
  shadows: [
    "none",
    "0 2px 5px 0px rgba(77, 77, 77, 0.25);",
    "0 4px 5px 0px rgba(77, 77, 77, 0.25);",
    "0 4px 5px 0px rgba(77, 77, 77, 0.25);",
    ...Array(21).fill("none"),
  ] as Shadows,
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Montserrat';
          font-style: normal;
          font-display: latin;
          font-weight: 400;
        }
      `,
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: "#dddddd",
        },
      },
    },
  },
})

export const darkTheme = createTheme({
  ...lightTheme,
  palette: {
    ...lightTheme.palette,
    mode: "dark",
    inherit: {
      main: "#fff",
      contrastText: "#4d4d4d",
    },
    primary: {
      main: "#FFAC2D",
      contrastText: "#422A00",
    },
    primaryDark: {
      main: "#7A4C00",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#20E381",
      contrastText: "#073B21",
    },
    teritary: {
      main: "#10CCB8",
      contrastText: "#052E27",
    },
    gray: {
      main: "#b5b3b3",
    },
    darkGray: {
      main: "#7a7979",
    },
    lightGray: {
      main: "#C0C0C0",
    },
    info: {
      main: "#b5b3b3",
    },
    white: {
      main: "#fff",
      contrastText: "#4d4d4d",
    },
    black: {
      main: "#fff",
    },
    background: {
      default: "#1e1e1e",
    },
    backgroundLightGray: {
      main: "#F2F2F2",
    },
    backgroundWhite: {
      main: "#333333",
    },
  },
  gradiant: {
    inherit: `linear-gradient(#FFD321, #FFAC2D)`,
    primary: "linear-gradient(#FFD321, #FFAC2D)",
    primaryBackground: "linear-gradient(#b39417, #b37820)",
    secondary: "linear-gradient(#19B064, #19B064, #0C998B)",
    secondaryBackground: "linear-gradient(#19B064, #0C998B)",
    teritary: "linear-gradient(#19B064, #19B064, #0C998B)",
    grayBackground: "linear-gradient(#666666, #999999)",
    lightGray: "linear-gradient(#ccc, #b8b6b6, #919090)",
    lightGrayBackground: "linear-gradient(#808080, #b3b3b3)",
    info: "linear-gradient(#ccc, #b8b6b6, #919090)",
    success: "linear-gradient(#20E381, #20E381, #10CCB8)",
    warning: `linear-gradient(#FFD321, #FFAC2D)`,
    error: "linear-gradient(#eb5550, #db4a46, #ff4444)",
    errorBackground: "linear-gradient(#eb5550, #ff4444)",
  },
  shadows: [
    "none",
    "0 2px 5px 0px rgba(36, 36, 36, 0.25);",
    "0 8px 10px 0px rgba(255, 255, 255, 0.10);",
    "0 4px 10px 0px rgba(255, 255, 255, 0.10);",
    ...Array(21).fill("none"),
  ] as Shadows,
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Montserrat';
          font-style: normal;
          font-display: latin;
          font-weight: 400;
        }
      `,
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: "#7a7979",
        },
      },
    },
  },
})
