"use client"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import React, { useCallback, useState } from "react"

import { Button } from "../../common/button"
import { Drawer } from "../../common/drawer"
import { Grid, GridRow } from "../../common/grid"

import styles from "./CB.module.scss"
import type {
  CookieBannerProps,
  CookieBannerActionButton,
} from "./CookieBanner.model"

import { CookieBannerSettings } from "./CookieBannerSettings"

import { create } from "@/helpers/bem"

const bem = create(styles, "CB")

export const CookieBanner = ({
  variant = "primary",
  content,
  settings,
  acceptButton,
  settingsButton,
  rejectButton,
  onClose,
  ...props
}: CookieBannerProps) => {
  const theme = useTheme()
  const screenSmallerMd = useMediaQuery(theme.breakpoints.down("md"))
  const [settingsOpen, setSettingsOpen] = useState(false)
  const handleChangeDialog = useCallback(
    () => setSettingsOpen(!settingsOpen),
    [settingsOpen, setSettingsOpen],
  )
  return (
    <>
      <Drawer
        disabled
        anchor="bottom"
        renderHeader={() => <></>}
        ModalProps={{
          disableScrollLock: true,
          sx: {
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(0, 0, 0, 0.3)",
            },
          },
        }}
        PaperProps={{
          sx: {
            height: "auto",
            backgroundColor: theme.palette.backgroundWhite.main,
          },
        }}
        sx={{
          padding: theme.spacing(2),
          "& .MuiPaper-root:before": {
            backgroundColor: "red",
          },
        }}
        onOpen={() => undefined}
        {...props}
        content=""
        onClose={() => undefined}
      >
        <Grid spacing={1}>
          <GridRow lg={8} md={7} xs={12}>
            <p className={bem("content")}>{content()}</p>
          </GridRow>
          <GridRow className={bem("buttons")} lg={4} md={5} xs={12}>
            <div>
              <Button
                className={bem("button")}
                fullWidth={screenSmallerMd}
                size="small"
                variant="outlined"
                {...settingsButton}
                onClick={() => setSettingsOpen(true)}
              />
            </div>
            <div>
              <span
                className={bem("button__custom")}
                role="button"
                tabIndex={0}
                onClick={(e) => {
                  rejectButton.onClick?.()
                  onClose?.(e)
                }}
                onKeyDown={(e) => {
                  if (e.code === "Enter") {
                    rejectButton.onClick?.()
                    onClose?.(e)
                  }
                }}
              >
                {rejectButton.title}
              </span>
            </div>
            <div>
              <Button
                className={bem("button")}
                fullWidth={screenSmallerMd}
                size="medium"
                variant="contained"
                {...acceptButton}
                onClick={(e) => {
                  acceptButton?.onClick?.()
                  onClose?.(e)
                }}
              />
            </div>
          </GridRow>
        </Grid>
      </Drawer>
      <CookieBannerSettings
        {...settings}
        open={settingsOpen}
        acceptButton={
          {
            ...acceptButton,
            ...settings.acceptButton,
          } as CookieBannerActionButton
        }
        rejectButton={
          {
            ...rejectButton,
            ...settings.rejectButton,
          } as CookieBannerActionButton
        }
        settingsButton={
          {
            ...settingsButton,
            ...settings.settingsButton,
          } as CookieBannerActionButton
        }
        onOpen={() => undefined}
        onClose={(e) => {
          handleChangeDialog()
          settings?.onClose?.(e)
        }}
      />
    </>
  )
}
