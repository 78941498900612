"use client"
import filter from "lodash-es/filter"
import isEqual from "lodash-es/isEqual"
import map from "lodash-es/map"
import dynamic from "next/dynamic"
import { useRouter, usePathname } from "next/navigation"
import { FC, useCallback, useMemo } from "react"

import styles from "./HeaderNavbar.module.scss"

import { SelectChangeEvent } from "@/components/common/select"
import { Skeleton } from "@/components/common/skeleton"
import type { NavbarProps, NavbarCallToAction } from "@/components/core/navbar"

import { client } from "@/config/apollo"
import { create } from "@/helpers/bem"
import { getLanguage } from "@/helpers/getLanguage"
import {
  trackMainNavigationState,
  trackLanguageChange,
  trackCTAAppDownloadRedirect,
  trackPageChange,
} from "@/helpers/tracking"
import { GET_COMPLEMENTARY_PAGE } from "@/queries/global"
import type {
  PageEntity,
  Article,
  ArticleEntity,
  CategoryEntity,
  I18NLocaleEntity,
  ComplementaryPageQuery,
  ComplementaryPageQueryVariables,
} from "@/types/generated/strapi/graphql"
import type { Locale } from "@/utils/translations"
import { i18n } from "@/utils/translations/i18n-config"

const Navbar = dynamic(
  () =>
    import("@/components/core/navbar").then(({ Navbar }) => ({
      default: Navbar,
    })),
  { loading: () => <Skeleton height={80} variant="rectangular" /> },
)

const bem = create(styles, "HeaderNavbar")

export type HeaderNavbarProps = {
  locale: Locale
  languages?: I18NLocaleEntity[]
} & Omit<NavbarProps, "languages" | "onChangeLanguage">

export const HeaderNavbar: FC<HeaderNavbarProps> = ({
  locale,
  languages,
  ...props
}) => {
  const router = useRouter()
  const path = usePathname()

  const resolveComplementarySlug = useCallback(
    (
      newLocale: Locale,
      type?: "page" | "article" | "category",
      slugs?: ArticleEntity | PageEntity | CategoryEntity,
    ) => {
      const attr = slugs?.attributes
      const defaultLocale = i18n.defaultLocale
      const isDefault = isEqual(defaultLocale, newLocale)
      const filteredLocalizations = filter(
        attr?.localizations?.data,
        (e: ArticleEntity | PageEntity | CategoryEntity) =>
          isEqual(e?.attributes?.locale, newLocale),
      )
      switch (type) {
        case "article": {
          const article = attr as Article
          const localizedArticle = filteredLocalizations?.[0]
            ?.attributes as Article
          const defaultArticleSlug = `/${defaultLocale}/blog/${article?.category.data?.attributes?.slug}/${article?.slug}`
          const localizedArticleSlug = `/${newLocale}/blog/${localizedArticle?.category?.data?.attributes?.slug}/${localizedArticle?.slug}`
          return isDefault || !localizedArticle
            ? defaultArticleSlug
            : localizedArticleSlug
        }
        case "category": {
          const defaultCategorySlug = `/${defaultLocale}/blog/${attr?.slug}`
          const localizedCategorySlug = `/${newLocale}/blog/${filteredLocalizations?.[0]?.attributes?.slug}`
          return isDefault || !filteredLocalizations?.[0]
            ? defaultCategorySlug
            : localizedCategorySlug
        }
        case "page": {
          const defaultPageSlug = `/${defaultLocale}/${attr?.slug}`
          const localizedPageSlug = `/${newLocale}/${filteredLocalizations?.[0]?.attributes?.slug}`
          return isDefault || !filteredLocalizations?.[0]
            ? defaultPageSlug
            : localizedPageSlug
        }
        default: {
          // Static mappings (not controlled by CMS)
          if (isEqual(locale, newLocale)) return
          const currentPath = path.replace(`/${locale}`, "")
          return `/${newLocale}${currentPath}`
        }
      }
    },
    [path, locale],
  )

  const onChangeLanguage = useCallback(
    async (e: SelectChangeEvent<unknown>) => {
      const newLocale = e?.target?.value as Locale
      const splittedPath = filter(path.split("/"), (e) => !isEqual(e, ""))
      const { data } = await client().query<ComplementaryPageQuery>({
        query: GET_COMPLEMENTARY_PAGE,
        variables: {
          locale: newLocale,
          slug: splittedPath[splittedPath.length - 1],
        } as ComplementaryPageQueryVariables,
      })
      let redirectTo
      const articleSlugs = data?.articles?.data
      if (articleSlugs && articleSlugs?.length > 0) {
        redirectTo = resolveComplementarySlug(
          newLocale,
          "article",
          articleSlugs?.[0] as ArticleEntity,
        )
      }
      const categorySlugs = data?.categories?.data
      if (categorySlugs && categorySlugs?.length > 0) {
        redirectTo = resolveComplementarySlug(
          newLocale,
          "category",
          categorySlugs?.[0] as CategoryEntity,
        )
      }
      const pageSlugs = data?.pages?.data
      if (pageSlugs && pageSlugs?.length > 0) {
        redirectTo = resolveComplementarySlug(
          newLocale,
          "page",
          pageSlugs?.[0] as PageEntity,
        )
      }
      const newSlug = redirectTo ?? resolveComplementarySlug(newLocale) ?? "/"
      trackLanguageChange(locale, newLocale)
      router.push(newSlug)
    },
    [router, path, locale, resolveComplementarySlug],
  )

  const onChangeMenu = (state: boolean) => trackMainNavigationState(state, path)

  const mappedLanguages = useMemo(
    () =>
      map(languages, (el) => {
        const lang = el?.attributes
        const code = lang?.code ?? ""
        return {
          label: lang?.name ?? "",
          value: code,
          icon: () => (
            <i
              className={bem("language__flag")}
              dangerouslySetInnerHTML={{
                __html: getLanguage(code).flag as string,
              }}
            />
          ),
        }
      }),
    [languages],
  )
  return (
    <Navbar
      {...props}
      languages={mappedLanguages}
      callToAction={
        {
          ...props.callToAction,
          onClick: (e) => {
            props.callToAction?.onClick?.(e)
            trackCTAAppDownloadRedirect("navigation", path)
          },
        } as NavbarCallToAction
      }
      onChangeLanguage={onChangeLanguage}
      onChangeMenu={onChangeMenu}
      onClickMenuLink={(name, slug) =>
        trackPageChange("mainnavigation", path, slug ?? "", name)
      }
    />
  )
}
