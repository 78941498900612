"use client"
import { useTheme } from "@mui/material/styles"
import isEqual from "lodash-es/isEqual"
import map from "lodash-es/map"
import size from "lodash-es/size"
import { usePathname } from "next/navigation"
import React, { ReactNode, Fragment, memo, useCallback } from "react"

import { Link, LinkVariants } from "../../common/link"

import styles from "./Breadcrumb.module.scss"

import { create } from "@/helpers/bem"

const bem = create(styles, "Breadcrumb")

export type BreadcrumbProps = {
  className?: string
  variant?: LinkVariants
  locale: string
  separator?: ReactNode
  capitalizeLinks?: boolean
  onClick?: (name?: string, slug?: string) => void
}

const formatLink = (text: string) => {
  const segments = text.split("-")
  const capitalizedSegments = map(
    segments,
    (segment) => segment.charAt(0).toUpperCase() + segment.slice(1),
  )
  return capitalizedSegments.join(" ")
}

export const Breadcrumb = memo(
  ({
    className,
    variant = "primary",
    locale,
    separator,
    capitalizeLinks,
    onClick,
  }: BreadcrumbProps) => {
    const theme = useTheme()
    const themeModifier = {
      [theme.palette.mode]: true,
    }
    const paths = usePathname()
    const pathNames = paths.split("/").filter((path) => path)

    const renderLink = useCallback(
      (href: string, children: ReactNode | string) => (
        <Link
          href={href}
          itemProp="item"
          variant={isEqual(variant, "primary") ? "inherit" : variant}
          onClick={() => onClick?.(children?.toString(), href)}
        >
          <span itemProp="name">{children}</span>
        </Link>
      ),
      [variant, onClick],
    )

    const renderListItem = useCallback(
      (key: number, children: ReactNode, hide?: boolean) => (
        <li
          key={key}
          itemScope
          itemProp="itemListElement"
          itemType="http://schema.org/ListItem"
          className={bem("list__item", {
            "is-hidden": !!hide,
          })}
        >
          {children}
        </li>
      ),
      [],
    )

    const renderSeperator = useCallback(
      () => <span className={bem("list__seperator")}>{separator ?? "– "}</span>,
      [separator],
    )
    const renderMeta = useCallback(
      (index: number) => (
        <meta content={index.toString()} itemProp="position" />
      ),
      [],
    )
    return (
      <nav
        aria-label="Breadcrumb"
        className={bem(
          undefined,
          {
            "is-hidden": isEqual(size(pathNames), 1),
          },
          className,
        )}
      >
        <ol
          itemScope
          className={bem("list", themeModifier)}
          itemType="http://schema.org/BreadcrumbList"
        >
          {renderListItem(
            0,
            <Fragment>
              {renderLink(`/${locale}/`, "Home")}
              <meta content="0" itemProp="position" />
            </Fragment>,
          )}
          {map(pathNames, (link, index) => {
            const next = index + 1
            const isLast = pathNames.length === next
            const slicedPath = pathNames.slice(0, next).join("/")
            const href = `/${slicedPath}`
            const itemLink = capitalizeLinks
              ? link?.[0]?.toUpperCase() + link.slice(1, link.length)
              : link
            const formatedLink = formatLink(itemLink)
            if (isLast) {
              return renderListItem(
                next,
                <Fragment>
                  {renderSeperator()}
                  <span itemProp="name">{formatedLink}</span>
                  {renderMeta(next)}
                </Fragment>,
                isEqual(locale, slicedPath),
              )
            }
            return renderListItem(
              next,
              <Fragment>
                {renderSeperator()}
                {renderLink(href, formatedLink)}
                {renderMeta(next)}
              </Fragment>,
              isEqual(locale, slicedPath),
            )
          })}
        </ol>
      </nav>
    )
  },
)

Breadcrumb.displayName = "Breadcrumb"
