"use client"
import { HttpLink, ApolloLink } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import {
  NextSSRApolloClient,
  ApolloNextAppProvider,
  NextSSRInMemoryCache,
  SSRMultipartLink,
} from "@apollo/experimental-nextjs-app-support/ssr"
import { PropsWithChildren } from "react"

import { getStrapiURL } from "../helpers/api-helpers"

const isServer = () => typeof window === `undefined`

export const authLink = setContext((_, { headers }) => {
  // get the authentication token from env
  const token = process.env.NEXT_PUBLIC_STRAPI_API_TOKEN
  if (!token) throw new Error("The Strapi API Token is not set.")
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  }
})

export function client() {
  const adjustedUri =
    !isServer() && process.env.NODE_ENV === "development"
      ? "http://localhost:1337"
      : getStrapiURL()
  const httpLink = new HttpLink({
    uri: `${adjustedUri}/graphql`,
  })
  const AuthHttpLink = authLink.concat(httpLink)
  return new NextSSRApolloClient({
    cache: new NextSSRInMemoryCache({
      addTypename: false,
    }),
    link:
      typeof window === "undefined"
        ? ApolloLink.from([
            new SSRMultipartLink({
              stripDefer: true,
            }),
            AuthHttpLink,
          ])
        : AuthHttpLink,
  })
}

export function ApolloWrapper({ children }: PropsWithChildren) {
  return (
    <ApolloNextAppProvider makeClient={client}>
      {children}
    </ApolloNextAppProvider>
  )
}
