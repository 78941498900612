"use client"
import Script from "next/script"
import type { FC } from "react"

import type {
  Maybe,
  ComponentMetaTracking,
} from "@/types/generated/strapi/graphql"

export type ScriptsProps = {
  settings?: Maybe<ComponentMetaTracking>
}

export const Tracking: FC<ScriptsProps> = ({ settings }) => {
  const GTMId = settings?.google?.GTMId
  if (!GTMId) return null
  return (
    <>
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTMId}" height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}></iframe>`,
        }}
      ></noscript>
      <Script
        id="gtm-init-script"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('consent', 'default', {
              'ad_storage': 'denied',
              'ad_user_data': 'denied',
              'ad_personalization': 'denied',
              'analytics_storage': 'denied'
            });
          `,
        }}
      />
      <Script
        id="gtm-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTMId}');
          `,
        }}
      />
    </>
  )
}
